import React, { Component } from "react"
import { graphql } from "gatsby"
import ImageGallery from "react-image-gallery"

import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"

import { strawShopImages } from "../../constants/images.js"
import { strawProduct } from "../../constants/products.js"
import { PostCodes } from "../../constants/postcodes"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { loadStripe } from "@stripe/stripe-js"
let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe( "pk_live_51HXRaHDaY5da5KQsMvGhiotwgm0AM4ZCXNW0JYPxSQ8FkbNYq7mESuIOeBNz7eQf58tpK1cBDlBRb9ZoKbkDuS6N00nuuF9mlg" )
    // stripePromise = loadStripe(
    //   "pk_test_51HXRaHDaY5da5KQsGDKiRd8iIo1iPKrLmlZ7LJwEXTjV0VjTXLjhUA0NnHb8Eql2o8zCSA4CM6Bq9egCJ3K54YRT00cOGHfS96"
    // )
  }
  return stripePromise
}

class ProductGallery extends React.Component {
  render() {
    return (
      <ImageGallery
        showFullscreenButton={false}
        showPlayButton={false}
        items={strawShopImages.carousel.items}
      />
    )
  }
}

export default class strawShopPage extends Component {
  componentDidMount() {
    console.log("componentDidMount")
    localStorage.setItem("quantity", "1")
  }
  constructor() {
    super()
    this.weight = React.createRef()
    // this.quantity = React.createRef()
    this.state = {
      product_price: "",
      post_code: "",
      active_zone: "",
      quantity: 1,
      collect_or_ship: "ship",
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleUserInput = this.handleUserInput.bind(this)
    this.increment = this.increment.bind(this)
    this.decrement = this.decrement.bind(this)
  }
  handleChange(e) {
    this.setState({
      product_price: e.target,
      product_vat: e.target.selectedOptions[0].dataset.price * 0.2,
    })
    localStorage.setItem("productName", e.target.selectedOptions[0].text)
    localStorage.setItem(
      "productPrice",
      e.target.selectedOptions[0].dataset.price
    )
    let productVat = e.target.selectedOptions[0].dataset.price * 0.2
    localStorage.setItem("productVat", productVat.toFixed(2))
  }

  handleUserInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
    localStorage.setItem([e.target.name], e.target.value)
  }

  orderHandle(value) {
    this.setState({ collect_or_ship: value })
  }
  postCodeCheck(codeToCheck) {
    let userPostCode = codeToCheck.replace(/ /g, "")
    this.setState({ post_code: userPostCode })
    if (userPostCode.length > 1) {
      localStorage.setItem("postCode", codeToCheck)
      let active_zone = ""
      PostCodes.zones.forEach(function(zone, index) {
        const codes = zone.post_codes.split(",")
        codes.forEach(function(code, index) {
          if (userPostCode.toUpperCase().includes(code)) {
            active_zone = zone
          }
        })
      })
      if (active_zone !== "") {
        this.setState({ active_zone: active_zone })
        localStorage.setItem("activeZone", active_zone.cost)
        let activeZoneVat = active_zone.cost * 0.2
        localStorage.setItem("activeZoneVat", activeZoneVat.toFixed(2))
      } else {
        active_zone = ""
      }
    }
  }

  increment() {
    this.setState({ quantity: ++this.state.quantity })
    localStorage.setItem("quantity", ++this.state.quantity)
  }

  decrement() {
    if (this.state.quantity > 1) {
      this.setState({ quantity: --this.state.quantity })
      localStorage.setItem("quantity", --this.state.quantity)
    } else {
      return false
    }
  }

  buyProduct = async e => {
    e.preventDefault()
    const form = e.target
    const stripe = await getStripe()

    // Make an API request to create a session
    // fetch(
    //   "https://www.strawpelletsdirect.com/.netlify/functions/create-session?actionType=createSession",
    //   {
    //     method: "GET",
    //     // Add any necessary headers
    //   }
    // )
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log("sessionID:", data.session.id)
    //     // Redirect to the Stripe Checkout page using the session ID
    //     const sessionId = data.session.id

    //     stripe.redirectToCheckout({
    //       sessionId: sessionId,
    //     })
    //   })
    //   .catch(error => {
    //     console.error("Error creating session:", error)
    //   })

    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
    })
    const product = this.weight.current.value
    const quant = this.state.quantity

    this.setState({ quantity: quant })
    const shipping = this.state.active_zone.stripe_price_id

    // Start with the product line item
    let lineItems = [
      {
        price: product,
        quantity: parseInt(quant),
      },
    ]

    // Conditionally add the shipping line item if it exists
    if (this.state.collect_or_ship === "ship" && shipping) {
      lineItems.push({
        price: shipping,
        quantity: parseInt(quant), // use the same quantity as the product as we calculate the cost of shipping by weight / item
      })
    }
	  
	console.log(lineItems);
	
    // Make an API request to create a session
    fetch(
      "https://www.strawpelletsdirect.com/.netlify/functions/stripe",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          actionType: "createSession",
          customerEmail: this.state.userEmail,
          lineItems: lineItems,
          successUrl: `${window.location.origin}/straw/success?session_id={CHECKOUT_SESSION_ID}`,
          cancelUrl: `${window.location.origin}`,
        }),
        credentials: "same-origin",
      }
    )
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText)
        }
        return response.json()
      })
      .then(data => {
        console.log("sessionID:", data.session.id)
        // Redirect to the Stripe Checkout page using the session ID
        const sessionId = data.session.id

        stripe.redirectToCheckout({
          sessionId: sessionId,
        })
      })
      .catch(error => {
        console.error("Error creating session:", error)
      })

    // const stripe = await getStripe()
    // if (this.state.collect_or_ship === "ship") {
    //   var { error } = await stripe.redirectToCheckout({
    // 	  mode: "payment",
    // 	  shippingAddressCollection: {
    // 		  allowedCountries: ["GB"],
    // 	  },
    //   lineItems: [
    // 	  {
    // 		  price: product,
    // 		  quantity: parseInt(quant),
    // 	  },
    // 	  {
    // 		  price: shipping,
    // 		  quantity: parseInt(quant),
    // 	  },
    //   ],
    // 	  customerEmail: this.state.userEmail,
    	//   successUrl: `${window.location.origin}/straw/success?session_id={CHECKOUT_SESSION_ID}`,
    	//   cancelUrl: `${window.location.origin}`,
    //   })
    // } else {
    //   var { error } = await stripe.redirectToCheckout({
    // 	  mode: "payment",
    // 	  shippingAddressCollection: {
    // 		  allowedCountries: ["GB"],
    // 	  },
    // 	  lineItems: [
    // 		  {
    // 			  price: product,
    // 			  quantity: parseInt(quant),
    // 		  }
    // 	  ],
    // 	  customerEmail: this.state.userEmail,
    // 	  successUrl: `${window.location.origin}/straw/success?session_id={CHECKOUT_SESSION_ID}`,
    // 	  cancelUrl: `${window.location.origin}`,
    //   })
    // }
    // if (error) {
    //   console.warn("Error:", error)
    // }
  }
  render() {
    const description = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(this.props.data.markdownRemark.frontmatter.description)
      .toString()
    return (
      <Layout pageInfo={{ pageName: "Buy Straw Pellets" }}>
        <SEO title="Buy Straw Pellets" />
        <div>
          <div className="fw banner-inner straw-banner-inner">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h1>Straw Pellets</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="fw warm-grey-bg watermark-center sect-pad-top sect-pad-bot">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <div className="fw padd-right-wrap">
                    <ProductGallery />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 large-p">
                  <div className="fw padd-left-wrap">
                    <h2 className="highlight">
                      {this.props.data.markdownRemark.frontmatter.title}
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                    <form
                      id="strawProduct"
                      method="POST"
                      action=""
                      name="productForm"
                      onSubmit={this.buyProduct}
                      onKeyPress={e => {
                        e.key === "Enter" && e.preventDefault()
                      }}
                    >
                      <div className="select">
                        <select
                          name="weight"
                          ref={this.weight}
                          onChange={this.handleChange}
                        >
                          <option
                            className="selectPlaceholder"
                            value
                            disabled
                            selected
                            data-price=""
                          >
                            Select weight...
                          </option>
                          {strawProduct.variations.map(variation => (
                            <option
                              value={variation.stripe_price_id}
                              data-price={variation.price}
                            >
                              {variation.text}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="product-price">
                        <h2>
                          {this.state.product_price !== ""
                            ? `£${this.state.product_price.selectedOptions[0]
                                .dataset.price * this.state.quantity}`
                            : ""}
                        </h2>
                        <h4>
                          {this.state.product_price !== ""
                            ? `+ £${
                                this.state.product_vat.toFixed(2) > 0
                                  ? this.state.product_vat.toFixed(2) *
                                    this.state.quantity
                                  : ""
                              } VAT`
                            : "Select Weight"}
                        </h4>
                      </div>
                      <div className="product-purchase">
                        <div className="input-group inline-group">
                          <div className="quantity-label">
                            <h4>Quantity:</h4>
                          </div>
                          <div className="quantity-buttons">
                            <div className="input-group-append">
                              <button
                                type="button"
                                onClick={this.increment}
                                className="btn-plus"
                              >
                                <p>+</p>
                              </button>
                            </div>
                            <div className="input-group-prepend">
                              <button
                                type="button"
                                onClick={this.decrement}
                                className="btn-minus"
                              >
                                <p>-</p>
                              </button>
                            </div>
                          </div>
                          <input
                            className="form-control quantity"
                            min={1}
                            name="quantity"
                            // defaultValue={1}
                            // type="number"
                            type="text"
                            // ref={this.quantity}
                            value={this.state.quantity}
                            // onChange={this.quantityCheck}
                          />
                          <div className="input-group inline-group radio-group">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="collect_shop"
                                id="ship_order"
                                checked={this.state.collect_or_ship === "ship"}
                                onChange={() => this.orderHandle("ship")}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="ship_order"
                              >
                                Please ship my order
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="collect_shop"
                                id="collect_order"
                                checked={
                                  this.state.collect_or_ship === "collect"
                                }
                                onChange={() => this.orderHandle("collect")}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="collect_order"
                              >
                                I will collect my order
                              </label>
                            </div>
                          </div>

                          {this.state.collect_or_ship == "ship" ? (
                            <span>
                              {this.state.active_zone !== ""
                                ? `+ £${this.state.active_zone.cost} Shipping on ${this.state.active_zone.name}`
                                : ""}
                            </span>
                          ) : (
                            <span className="collection-details">
                              <h4>Collecting your order</h4>
                              <p>Collection Monday to Friday 8am-4pm</p>
                              <p>
                                Tickfold Farm
                                <br />
                                Kingsfold
                                <br />
                                Nr Horsham
                                <br />
                                West Sussex
                                <br />
                                RH12 3SE
                              </p>
                              <p>
                                <strong>
                                  **Please wait for confirmation that your
                                  pallet is ready for collection.
                                </strong>
                              </p>
                            </span>
                          )}

                          {this.state.collect_or_ship == "ship" ? (
                            <div className="input-group inline-group">
                              <div className="quantity-label">
                                <h4>Shipping postcode:</h4>
                              </div>
                              <input
                                placeholder="Postcode"
                                className="form-control postcode"
                                type="text"
                                name="postcode"
                                autocomplete="off"
                                onInput={e => {
                                  this.postCodeCheck(e.target.value)
                                }}
                              ></input>
                            </div>
                          ) : null}
                          <div className="input-group pb-4">
                            <input
                              className="form-control"
                              placeHolder="Email"
                              name="userEmail"
                              type="email"
                              onChange={this.handleUserInput}
                            />
                          </div>
                          <div className="input-group pb-4">
                            <input
                              className="form-control"
                              placeHolder="Telephone"
                              name="userPhone"
                              type="tel"
                              onChange={this.handleUserInput}
                            />
                          </div>
                          {this.state.collect_or_ship == "ship" ? (
                            <input
                              type="submit"
                              id="submit"
                              value="Add to cart"
                              className="btn w-100"
                              disabled={
                                this.state.active_zone &&
                                this.state.product_price &&
                                this.state.userEmail &&
                                this.state.userPhone
                                  ? ""
                                  : "disabled"
                              }
                            />
                          ) : (
                            <input
                              type="submit"
                              id="submit"
                              value="Add to cart"
                              className="btn w-100"
                              disabled={
                                this.state.product_price &&
                                this.state.userEmail &&
                                this.state.userPhone
                                  ? ""
                                  : "disabled"
                              }
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query StrawProductQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pstraw.md/" }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
